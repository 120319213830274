h1 {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 500;
}

h2 {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 500;
}


.center {
  display: flex;
  justify-content: center;
  align-items: center;
}